@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .wrapper {
            gap: 10px;

            span {
                gap: 10px;
                line-height: 100%;
                @include flex;
                @include align-items(center);

                &.active {
                    color: rgba(133, 177, 214, 1);

                }

                a {

                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: underline;
                    }
                }

                &:not(:last-child) {
                    &:after {
                        content: "";
                        width: 13px;
                        height: 13px;
                        display: block;
                        background-image: url("/site-assets/svg/breadcrumb-arrow.svg");
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                }
            }
        }
    }
}