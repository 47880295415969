@use "@/styles/variables" as *;
@use "@/styles/mixins" as mix;

.root {
    :global {
        svg {
            circle {
                fill: transparent;
            }

            path {
                fill: $bg-dark;
                @include mix.trans;
            }
        }

        button {
            margin: 0;

            &:hover,
            &:focus,
            &:active {
                svg {
                    path {
                        fill: $primary-color;
                    }
                }
            }
        }

        .inner-wrapper {
            gap: 0px;
            max-width: 200px;
            margin: 0 auto;
        }
    }

    &:global.blog-style {
        border: none;
        display: flex;
        background: $secondary-color;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 21.6px;
        padding: 20px;
        gap: 22px;
        color: rgba(75, 53, 5, 1);

        .inner-wrapper {
            padding: 0 !important;
            margin: 0;

            svg {
                path {
                    fill: $base-black;
                }
            }

            button {
                // background: #0000001A !important;
                // border-radius: 3px;
                @include mix.trans;

                svg {
                    width: 40px;
                    height: 40px;
                }

                &:hover,
                &:focus,
                &:active {
                    // background: $primary-color !important;
                    opacity: 0.5;

                    svg {
                        path {
                            fill: $base-black;
                        }
                    }
                }
            }
        }

    }

}