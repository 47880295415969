@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .wrapper {
            @include flex;
            @include flex-wrap(wrap);
            gap: 30px;
        }

        .content {
            color: var(--Cool-Grey, rgba(95, 94, 94, 1));

            p {
                color: var(--Cool-Grey, rgba(95, 94, 94, 1));

            }

            h1,
            h2 {
                font-size: 47px;
                color: $font-color;
            }

            ul {
                padding-left: 0;
                list-style: none;

                li {
                    position: relative;
                    padding-left: 1.2rem;

                    &:not(:last-child) {
                        margin-bottom: 0.5rem;
                    }

                    &:before {
                        content: "";
                        left: 0;
                        width: 12px;
                        top: 7px;
                        height: 12px;
                        background: $primary-color;
                        position: absolute;
                        border-radius: 100%;
                    }
                }
            }
        }

        img {
            max-width: 100%;
            object-fit: contain;
        }

        @media (min-width: $breakpoint-lg) {
            img {
                min-width: 383px;
                max-width: 365px;
                object-fit: contain;
            }

            .wrapper {
                @include flex-wrap(nowrap);

                >div {
                    &:nth-child(1) {
                        max-width: 383px;
                        min-width: 383px;
                    }
                }
            }
        }
    }
}