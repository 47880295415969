@import "styles/mixins";
@import "styles/variables";


.root {
    position: relative;

    &.loading {
        @keyframes spinner {
         to {transform: rotate(360deg);}
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba($primary-color, 0.5);
            border-radius: 10px;
        }
        &:after {
            content: "";
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 6rem;
            height: 6rem;
            margin-top: -3rem;
            margin-left: -3rem;
            border-radius: 50%;
            border-top: 5px solid #fff;
            border-right: 5px solid transparent;
            animation: spinner .6s linear infinite;
        }
    }

    :global {

        .utm_source,
        .utm_medium,
        .utm_campaign,
        .page_submitted_on {
            display: none;
        }

        .formio-component-form {
            background: $primary-color;
            padding: 30px;
            border-radius: 10px;
            color: $base-white;
            font-family: $font-family;

            .formio-select-autocomplete-input {
                display: none;
            }

            .formio-component {

                &-file {
                    margin-bottom: 1.5rem;

                    .list-group {
                        .row {
                            width: 100%;
                        }

                        a {
                            cursor: pointer;
                            color: $primary-color;

                            &:hover,
                            &:focus,
                            &:active {
                                text-decoration: underline;
                            }
                        }

                        .fa-remove {
                            &:before {
                                content: "x";
                                cursor: pointer;
                                color: $primary-color;
                            }

                            width: 100%;
                            height: 38px;
                            display: block;

                        }

                        .list-group-item {
                            background: $base-white;
                            padding: 5rem 2rem;
                            border-radius: 40px;
                            color: $font-color;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: 100;

                            &:first-child {
                                display: none !important
                            }
                        }
                    }

                    .fileSelector {
                        background: $base-white;
                        padding: 5rem 2rem;
                        border-radius: 40px;
                        color: $font-color;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: center;

                        .fa-cloud-upload {
                            width: 38px;
                            height: 38px;
                            display: block;
                            background-image: url('data:image/svg+xml,<svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.56253 23.625C5.85446 23.6268 4.19783 23.0406 2.87102 21.9649C1.5442 20.8892 0.628076 19.3896 0.276571 17.7181C-0.0749332 16.0466 0.159609 14.305 0.940788 12.786C1.72197 11.2671 3.00217 10.0633 4.56628 9.37691C4.13284 7.56101 4.39817 5.64851 5.30959 4.01919C6.22101 2.38988 7.71185 1.16291 9.48606 0.581944C11.2603 0.000975758 13.1881 0.108499 14.8867 0.883162C16.5853 1.65783 17.9305 3.04297 18.655 4.76353C19.5642 4.4645 20.5372 4.4166 21.4713 4.6249C22.4054 4.83321 23.2658 5.28998 23.9618 5.94694C24.6577 6.60391 25.1632 7.43667 25.425 8.35723C25.6867 9.27779 25.6949 10.252 25.4487 11.1768C26.8956 11.6419 28.1288 12.6086 28.9261 13.9025C29.7234 15.1964 30.0324 16.7326 29.7974 18.2342C29.5623 19.7357 28.7987 21.104 27.6441 22.0924C26.4895 23.0808 25.0199 23.6243 23.5 23.625H7.56253ZM9.09253 12.9363C8.81101 13.2384 8.65775 13.638 8.66503 14.0509C8.67232 14.4638 8.83958 14.8577 9.13158 15.1497C9.42359 15.4417 9.81753 15.609 10.2304 15.6163C10.6433 15.6236 11.0429 15.4703 11.345 15.1888L13.4063 13.1275V18.8438C13.4063 19.2665 13.5742 19.6718 13.8731 19.9707C14.172 20.2696 14.5773 20.4375 15 20.4375C15.4227 20.4375 15.8281 20.2696 16.127 19.9707C16.4259 19.6718 16.5938 19.2665 16.5938 18.8438V13.1275L18.655 15.1888C18.8009 15.3454 18.9769 15.471 19.1724 15.5581C19.3679 15.6452 19.5789 15.692 19.7929 15.6958C20.0069 15.6996 20.2195 15.6602 20.4179 15.58C20.6164 15.4999 20.7966 15.3806 20.948 15.2292C21.0993 15.0779 21.2186 14.8976 21.2988 14.6992C21.379 14.5007 21.4183 14.2882 21.4145 14.0742C21.4108 13.8602 21.3639 13.6491 21.2768 13.4536C21.1897 13.2581 21.0641 13.0822 20.9075 12.9363L16.1263 8.15503C15.8275 7.85657 15.4224 7.68893 15 7.68893C14.5777 7.68893 14.1726 7.85657 13.8738 8.15503L9.09253 12.9363Z" fill="%23B9B9B9"/></svg>');
                            background-repeat: no-repeat;
                            background-size: contain;
                            margin-right: 8px;
                        }

                        .browse {
                            color: $primary-color;
                            margin-left: 5px;

                            &:hover,
                            &:focus,
                            &:active {
                                text-decoration: underline;
                            }
                        }
                    }

                    @media (max-width: $breakpoint-md) {
                        .fileSelector {
                            padding: 4rem 1.5rem;
                        }

                        .list-group {
                            .list-group-item {
                                padding: 4rem 1.5rem;
                            }
                        }
                    }
                }

                &.formio-component-submit {
                    button {
                        @include primaryBtn($chevron: false, $arrow: true);
                        width: 100%;
                        @include flex;
                        @include justify-content(center);
                        box-shadow: 0px 7px 9px 0px rgba(0, 50, 92, 1);
                        margin: 1.5rem 0 0 0;

                        &:disabled {
                            opacity: 1;
                            cursor: not-allowed;
                            pointer-events: initial;
                        }

                    }
                }

                &.formio-component-checkbox,
                &.formio-component-selectboxes {
                    .form-radio {
                        margin-bottom: 1rem;
                    }

                    .form-check-label {
                        gap: 10px;
                        display: flex;
                        align-items: center;
                    }

                    input {
                        width: 28px;
                        height: 28px;
                        border-radius: 5px;
                        margin-bottom: 0;
                        padding: initial;

                        &:checked {
                            background: $base-white;
                            background-repeat: no-repeat;
                            background-position: 3px center;
                            background-size: 80%;
                            background-image: url('data:image/svg+xml,<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.6925 14.3441L0 8.65162L2.59417 6.05746L5.6925 9.16496L14.7492 0.0991211L17.3433 2.69329L5.6925 14.3441Z" fill="%2335A723"/></svg>');
                        }

                    }
                }

                &.formio-component-htmlelement {
                    h3 {
                        font-size: 27px;
                        line-height: 36.45px;
                    }
                }

                input,
                textarea,
                select {
                    padding: 10px 20px;
                    border: none;
                    line-height: 100%;
                    border-radius: 40px;
                    margin-bottom: 1.5rem;
                }

                textarea {
                    padding: 20px;
                    border-radius: 10px;
                }

                select {
                    padding: 14.5px 20px;
                }

                label {
                    font-weight: 700;
                    line-height: 21.6px;

                    &.field-required {
                        &:after {
                            content: "*";
                            color: $secondary-color;
                        }
                    }
                }
            }

        }
    }
}