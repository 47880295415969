@import "styles/mixins";
@import "styles/variables";

.root {
    :global {
        position: relative;
        background: $primary-color;
        color: $base-white;
        overflow: hidden;

        .wrapper {
            max-width: 1100px;
        }

        p {
            color: rgba(244, 244, 244, 1);
            font-size: 21px;
            line-height: 28.35px;
        }

        .wave {
            position: relative;
            top: 1px;
            &.light-grey {
                svg {
                    path {
                        fill: rgba(244, 244, 244, 1);
                    }
                }
            }
        }

        .waveChild {
            position: relative;
            top: 1px;
            svg {
            path {
                fill: #f4f4f4;
            }
           }
            
        }

        .floating-icons {
            position: absolute;

            &:nth-of-type(1) {
                left: -80px;
                top: -20px;
            }

            &:nth-of-type(2) {
                right: 0;
                top: 50px;
            }

            &:nth-of-type(3) {
                right: 350px;
                top: 10px;
            }

            &:nth-of-type(4) {
                right: 160px;
                bottom: 0;
            }

            &:nth-of-type(5) {
                left: 45%;
                top: 0;
            }
        }

        .overlay-dots {
            position: absolute;

            &:nth-of-type(2) {
                left: -150px;
                bottom: -150px;
            }

            &:nth-of-type(3) {
                right: -30px;
                top: -60px;
            }
        }

        @media (max-width: $breakpoint-lg) {
            h1 {
                font-size: 36px;
            }

            .wave {
                bottom: -8px;
                top: auto;
            }

            .floating-icons {
                svg {
                    transform: scale(0.6) !important;

                }

                &:nth-child(1) {
                    top: auto;
                    right: auto;
                    bottom: -85px;
                    left: 30px;
                }

                &:nth-child(2) {
                    top: auto;
                    right: auto;
                    bottom: -50px;
                    left: 140px;
                }

                &:nth-child(3) {
                    top: auto;
                    right: auto;
                    bottom: -60px;
                    left: 0;
                }

                &:nth-child(4) {
                    top: auto;
                    right: 105px;
                    left: auto;
                    bottom: -70px;
                }

                &:nth-child(5) {
                    top: auto;
                    right: 15px;
                    left: auto;
                    bottom: -50px;
                }

                &:nth-child(6) {
                    top: auto;
                    right: 150px;
                    left: auto;
                    bottom: -120px;


                }
            }

        }
    }

    &:global.jobs-page {
        padding-bottom: 130px;
        background-position: center 101%;
        background-repeat: no-repeat;
        background-size: 103% auto;
        background-image: url('data:image/svg+xml,<svg width="1440" height="80" viewBox="0 0 1440 80" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_3663_23920)"><rect width="1440" height="80" transform="matrix(-1 0 0 -1 1440 80)" fill="%23005CA9"/><path d="M-0.000184374 79.9999L3.81959e-05 0.49976C314 51 986 61.5 1440 0.49976L1440 80L-0.000184374 79.9999Z" fill="%23F4F4F4"/><path d="M961.735 -439.759C928.263 -125.208 145.183 42.6526 -787.363 -64.824C-1061.61 -96.4252 -1318.19 -148.333 -1543 -213.875C-1257.53 -98.8604 -881.003 -5.85744 -463.713 42.2327C465.773 149.345 1245.1 -6.82025 1276.99 -306.606C1295.31 -478.783 1063 -660.858 685.859 -805C871.764 -689.423 974.778 -562.489 961.735 -439.759Z" fill="white" fill-opacity="0.02"/></g><defs><clipPath id="clip0_3663_23920"><rect width="1440" height="80" fill="white" transform="matrix(-1 0 0 -1 1440 80)"/></clipPath></defs></svg>');

        .js-container {
            .js-form {
                .js-input-location,
                .js-input-radius {
                    outline: none;
                    box-shadow: none;
                    padding: 0;
                    border: none;
                }

                .js-input-wrapper {
                    margin-left: 0;
                }

                .js-input-wrapper {
                    &.js-input-wrapper-location {
                        &:before {
                            content: "";
                            background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 8.625C8.50272 8.625 8.02581 8.42746 7.67417 8.07583C7.32254 7.72419 7.125 7.24728 7.125 6.75C7.125 6.25272 7.32254 5.77581 7.67417 5.42417C8.02581 5.07254 8.50272 4.875 9 4.875C9.49728 4.875 9.97419 5.07254 10.3258 5.42417C10.6775 5.77581 10.875 6.25272 10.875 6.75C10.875 6.99623 10.8265 7.24005 10.7323 7.46753C10.638 7.69502 10.4999 7.90172 10.3258 8.07583C10.1517 8.24994 9.94502 8.38805 9.71753 8.48227C9.49005 8.5765 9.24623 8.625 9 8.625ZM9 1.5C7.60761 1.5 6.27226 2.05312 5.28769 3.03769C4.30312 4.02226 3.75 5.35761 3.75 6.75C3.75 10.6875 9 16.5 9 16.5C9 16.5 14.25 10.6875 14.25 6.75C14.25 5.35761 13.6969 4.02226 12.7123 3.03769C11.7277 2.05312 10.3924 1.5 9 1.5Z" fill="%23005CA9"/></svg>');
                        }
                    }

                    &.js-input-wrapper-radius {
                        &:before {
                            content: "";
                            background-image: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28 16C28 18.6478 27.1242 21.2213 25.5092 23.3195C23.8941 25.4178 21.6304 26.9229 19.0707 27.6005C16.5111 28.278 13.7992 28.09 11.3575 27.0656C8.91588 26.0412 6.88159 24.2381 5.57158 21.937C4.26157 19.636 3.74939 16.9662 4.11485 14.3437C4.48031 11.7213 5.70287 9.29328 7.59208 7.43805C9.4813 5.58283 11.9311 4.40454 14.5598 4.08674C17.1884 3.76895 19.8484 4.3295 22.1253 5.68107" stroke="%23005CA9"/><circle cx="15.781" cy="15.9488" r="3.03898" transform="rotate(-30.6068 15.781 15.9488)" fill="%23005CA9"/><circle cx="26.8847" cy="9.37979" r="1.54849" transform="rotate(-30.6068 26.8847 9.37979)" stroke="%23005CA9"/><line x1="17.6155" y1="14.7868" x2="25.836" y2="9.92394" stroke="%23005CA9"/></svg>');
                        }
                    }
                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            .js-container {
                .js-form {
                    .js-input-wrapper {
                        &:not(:last-of-type) {
                            border-right: 1px solid rgba($base-black, 0.2);
                            margin-right: 3rem;
                        }
                    }
                }
            }
        }

        @media (max-width: $breakpoint-lg) {
            padding-bottom: 100px;
            h1 {
                font-size: 47px;
                line-height: 56.4px;
            }
        }
    }

    &:global.cpd-page {
        padding-bottom: 1.5rem;
        @media (min-width: $breakpoint-lg) {
            padding-bottom: 5rem;
        }
    }

    &:global.cpd-the-national-college-page {
        padding-bottom: 5rem;
        .wrapper {
            width: 100%;
            max-width: 100%;
            > div {
                &:nth-child(1) {
                    max-width: 850px;
                }
            }
        }

        .tnc-logo {
            margin-right: 100px;
            position: relative;
            top: 0;
            z-index: 1;
            max-width: 150px;
            margin-bottom: -40px;
            img {
                border-radius: 6px;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }

        @media (min-width: $breakpoint-lg) {
            .tnc-logo {
                margin-bottom: 0;
                top: 40px;
                max-width: 210px;
            }
        }
    }

    &:global.cpd-the-national-college-page:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 100px;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        background-image: url('data:image/svg+xml,<svg width="1440" height="80" viewBox="0 0 1440 80" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M5.97663e-05 79.9999L0.000282337 0.49976C314 51 986 61.5 1440 0.49976L1440 80L5.97663e-05 79.9999Z" fill="%23F4F4F4"/></svg>');

        @media (min-width: $breakpoint-lg) {
            padding-bottom: 5%;
        }
    }

    &:global.resources-page {
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 101% auto;
        background-image: url('data:image/svg+xml,<svg width="1440" height="80" viewBox="0 0 1440 80" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_3615_53204)"><rect width="1440" height="80" transform="matrix(-1 0 0 -1 1440 80)" fill="%23005CA9"/><path d="M5.97663e-05 79.9999L0.000282337 0.49976C314 51 986 61.5 1440 0.49976L1440 80L5.97663e-05 79.9999Z" fill="%23FFB81C"/><path d="M961.734 -439.759C928.263 -125.208 145.183 42.6526 -787.363 -64.824C-1061.61 -96.4252 -1318.19 -148.333 -1543 -213.875C-1257.53 -98.8604 -881.003 -5.85744 -463.714 42.2327C465.772 149.345 1245.1 -6.82025 1276.99 -306.606C1295.31 -478.783 1063 -660.858 685.859 -805C871.764 -689.423 974.777 -562.489 961.734 -439.759Z" fill="white" fill-opacity="0.02"/></g><defs><clipPath id="clip0_3615_53204"><rect width="1440" height="80" fill="white" transform="matrix(-1 0 0 -1 1440 80)"/></clipPath></defs></svg>');
        padding-bottom: 6%;

        h1 {
            text-transform: capitalize;
        }

        .wrapper {
            span {
                &.active {
                    text-transform: capitalize;
                }
            }
        }

    }

    &:global.blog-page {
        background: linear-gradient(0deg, rgba(0, 92, 169, 0.89), rgba(0, 92, 169, 0.89)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

        .container {

            >.wrapper {
                margin-bottom: 2.5rem;
                min-width: 100%;

                >div {
                    &:nth-child(1) {
                        max-width: 800px;
                        width: 100%;
                    }
                }
            }
        }

        img {
            &.overlay-img {
                opacity: 0.1;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                object-fit: cover;
            }
        }

        .author {
            img {
                max-width: 43px;
                max-height: 43px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;
            }
        }

        .tag {
            padding: 6px;
            font-size: 12px;
            line-height: 16.2px;
            background: $tertiary-color;
            color: rgba(0, 0, 0, 0.7);
            border-radius: 10px;

            &.sen {
                background: rgba(131, 118, 211, 1);

            }

            &.primary {
                background: rgba(245, 76, 106, 1);
                color: $font-color;
            }

            &.secondary {
                background: rgba(76, 201, 240, 1);
                color: $font-color;

            }

            &.teaching-assistant {
                background: rgba(255, 184, 28, 1);
                color: $font-color;
            }

            &.school-support-jobs {
                background: rgba(0, 92, 142, 1);
                color: $base-white;
            }

            &.ect {
                background: rgba(66, 232, 142, 1);
                color: $font-color;
            }


        }


        @media (max-width: $breakpoint-lg) {
            h1 {
                font-size: 27px;
            }

        }

        @media (min-width: $breakpoint-lg) {

            .social-share {
                margin-top: 3rem;
            }
        }

    }
}